import { useState, useRef, createRef, useEffect, useCallback, forwardRef } from "react";
import styles from "./index.module.less";
import { SSE, Ppt2Svg, Ppt2Canvas } from "aippt";
import pako from "pako";
import base64js from "base64-js";
import cls from "classnames";
import { message } from "antd";

let pptxObj = null;
let painter = null;
const canvasList = [];

function resetSize() {
  // const width = Math.max(Math.min(document.body.clientWidth - 400, 1600), 480);
  // painter.resetSize(width, width * 0.5625);
  const el = document.getElementsByClassName("left-svg")[0];
  if (el) {
    const width = el.clientWidth;
    const height = el.clientHeight;
    painter.resetSize(width, height);
  }
}

const GeneratePage = forwardRef((props, ref) => {
  const { pptToken, templateId, outline, dataUrl, currentPage, setPercent, messageId, historyPptId } = props;
  const [gening, setGening] = useState(false);
  const svg = useRef(null);
  const [pptxId, setPptxId] = useState("");
  const [pages, setPages] = useState([]);
  const [currentIdx, setCurrentIdx] = useState(0);
  const [pageTotal, setPageTotal] = useState(0);

  useEffect(() => {
    painter = new Ppt2Svg(svg.current);
    painter.setMode("edit");
    let mTimer = 0;
    window.addEventListener("resize", function () {
      mTimer && clearTimeout(mTimer);
      mTimer = setTimeout(() => {
        resetSize();
      }, 50);
    });
    resetSize();
    const _pptxId = historyPptId;
    if (_pptxId) {
      loadById(_pptxId);
    } else {
      generatePptx(templateId, outline, dataUrl);
    }
  }, [pptToken]);

  useEffect(() => {
    if (currentPage === 1) return;
    if (gening && currentIdx > 0) {
      canvasList[currentIdx - 1].current.scrollIntoView(true);
    } else if (canvasList.length > 0 && currentIdx === 0) {
      canvasList[0].current && canvasList[0].current.scrollIntoView(true);
    }
    if (canvasList.length > 0) {
      for (let i = 0; i < pages.length; i++) {
        const imgCanvas = canvasList[i].current;
        if (!imgCanvas) {
          continue;
        }
        try {
          const _ppt2Canvas = new Ppt2Canvas(imgCanvas);
          console.log("pptobject", pptxObj);
          _ppt2Canvas.drawPptx(pptxObj, i);
        } catch (e) {
          console.log(`渲染第${i + 1}页封面异常`, e);
        }
      }
    }
  }, [gening, pages]);

  const generatePptx = useCallback(
    (templateId, outline, dataUrl) => {
      setGening(true);
      const url = "https://docmee.cn/api/ppt/generateContent";
      const source = new SSE(url, {
        method: "POST",
        headers: {
          token: pptToken,
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
        },
        payload: JSON.stringify({ asyncGenPptx: true, templateId, outlineMarkdown: outline, dataUrl }),
      });
      source.onmessage = function (data) {
        const json = JSON.parse(data.data);
        if (json.pptId) {
          asyncGenPptxInfo(json.pptId);
        }
      };
      source.onend = function (data) {
        if (data.data.startsWith("{") && data.data.endsWith("}")) {
          const json = JSON.parse(data.data);
          if (json.code !== 0) {
            message.warning(`生成PPT异常：${json.message}`);
            return;
          }
        }
        setGening(false);
        setTimeout(() => {
          drawPptxList(0, false);
        }, 200);
      };
      source.onerror = function (err) {
        console.error("生成内容异常", err);
        message.warning("生成内容异常");
      };
      source.stream();
    },
    [pptToken]
  );

  const asyncGenPptxInfo = useCallback(
    (id) => {
      setPptxId(id);
      const url = `https://docmee.cn/api/ppt/asyncPptInfo?pptId=${id}`;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("token", pptToken);
      xhr.send();
      xhr.onload = function () {
        if (this.status === 200) {
          const resp = JSON.parse(this.responseText);
          const gzipBase64 = resp.data.pptxProperty;
          const gzip = base64js.toByteArray(gzipBase64);
          const json = pako.ungzip(gzip, { to: "string" });
          const _pptxObj = JSON.parse(json);
          pptxObj = _pptxObj;
          drawPptxList(resp.data.current - 1, true);
          setPageTotal(resp.data.total);
          setPercent(Math.floor((resp.data.current / resp.data.total) * 100).toFixed(2));
        }
      };
      xhr.onerror = function (e) {
        console.error(e);
      };
    },
    [pptToken]
  );

  const drawPptxList = useCallback(
    (_idx, asyncGen) => {
      const idx = _idx || 0;
      setCurrentIdx(idx);
      if (_idx === null || asyncGen) {
        const _pages = [];
        for (let i = 0; i < pptxObj.pages.length; i++) {
          if (asyncGen && i > idx) {
            break;
          }
          _pages.push(pptxObj.pages[i]);
        }
        setPages(_pages);
      } else {
        setPages(pptxObj.pages || []);
      }

      drawPptx(idx);
    },
    [pptToken]
  );

  const drawPptx = useCallback((idx) => {
    setCurrentIdx(idx);
    painter.drawPptx(pptxObj, idx);
  }, []);

  // 展示历史ppt
  const loadById = useCallback(
    (id) => {
      setGening(false);
      setPptxId(id);
      const url = `https://docmee.cn/api/ppt/loadPptx?id=${id}`;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.setRequestHeader("token", pptToken);
      xhr.send();
      xhr.onload = function () {
        if (this.status === 200) {
          const resp = JSON.parse(this.responseText);
          if (resp.code !== 0) {
            alert(resp.message);
            return;
          }
          const pptInfo = resp.data.pptInfo;
          const gzipBase64 = pptInfo.pptxProperty;
          const gzip = base64js.toByteArray(gzipBase64);
          const json = pako.ungzip(gzip, { to: "string" });
          pptInfo.pptxProperty = JSON.parse(json);
          pptxObj = pptInfo.pptxProperty;
          drawPptxList();
        }
      };
      xhr.onerror = function (e) {
        console.error(e);
      };
    },
    [pptToken]
  );

  const downloadPptx = useCallback(
    (id) => {
      const url = "https://docmee.cn/api/ppt/downloadPptx";
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("token", pptToken);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify({ id }));
      xhr.onload = function () {
        if (this.status === 200) {
          const resp = JSON.parse(this.responseText);
          const fileUrl = resp.data.fileUrl;
          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = `${resp.data.subject || "download"}.pptx`;
          a.click();
        }
      };
      xhr.onerror = function (e) {
        console.error(e);
      };
    },
    [pptToken]
  );

  return (
    <div className={styles.generatePage}>
      <div className="left">
        <div className="left-svg">
          <svg
            ref={svg}
            className="svg-canvas"
            preserveAspectRatio="none"
          ></svg>
          {!gening ? (
            <div className="page">{`第${currentIdx + 1}页/共${pages.length}页`}</div>
          ) : (
            <div className="page">{`第${currentIdx + 1}页/共${pageTotal}页`}</div>
          )}
        </div>
      </div>
      <div className="right">
        <div className="title">PPT预览</div>
        <div className="right-perview">
          <div className="right-perview-content">
            {pages.map((page, index) => {
              canvasList[index] = createRef();
              return (
                <div
                  key={index}
                  onClick={() => drawPptx(index)}
                  className={cls("ppt-item", {
                    active: currentIdx === index,
                  })}
                >
                  <canvas
                    ref={canvasList[index]}
                    width="288"
                    height="162"
                    className={cls("ppt-item-img")}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="btns">
          {!gening && pptxId ? (
            <div
              className="btns-item download"
              onClick={() => downloadPptx(pptxId)}
            >
              下载PPT
            </div>
          ) : (
            <div className="btns-item generate">PPT正在生成中......</div>
          )}
        </div>
      </div>
    </div>
  );
});

GeneratePage.displayName = "GeneratePage";
export default GeneratePage;
