import { get, post, remove, put, patch, upload } from "./utils";
export const data = {
  ask: (parameters) => post("/api/data/", parameters),
  getAnswers: (id) => get(`/api/data/${id}/`, {}),
  reAnswer: (id) => post(`/api/data/${id}/re_answer/`, {}),
  removeAnswer: (id) => remove(`/api/data/${id}/`, {}),
  removeAnswers: (parameters) => remove(`/api/data/patch_delete/`, parameters),
  addSession: (parameters) => post(`/api/session/`, parameters),
  getSessions: (parameters) => get("/api/session/my/", parameters),
  getSessionByPage: (parameters) => get("/api/session/my_page/", parameters),
  getSession: (id) => get(`/api/session/${id}/`, {}),
  sessionDataNames: (id, parameters) => get(`/api/session/${id}/data_names/`, parameters),
  updateSession: (id, parameters) => patch(`/api/session/${id}/`, parameters),
  bulkRemoveSession: (parameters) => post(`/api/session/patch_delete/`, parameters),
  clearSession: (id) => post(`/api/session/${id}/clear/`, {}),
  bulkMoveSession: (parameters) => post(`/api/session/patch_move/`, parameters),
  bulkCopySession: (parameters) => post(`/api/session/patch_copy/`, parameters),

  // paper
  getPaperList: (parameters) => get(`/api/paper/my_page/`, parameters),
  paperBulkRemoveSession: (parameters) => post(`/api/paper/patch_delete/`, parameters),
  // 用户信息
  getToken: () => get(`/api/usertoken/my/`, {}),
  // 获客详情
  getAttractUsers: (user, parameters) => get(`/api/usertoken/${user}/attract_users/`, parameters),
  getAttractTotalAmount: (user) => get(`/api/usertoken/${user}/attract_total_amount/`, {}),
  // 用户详情
  getUserInfo: (user, parameters) => get(`/api/usertoken/${user}/info/`, parameters),
  // 获取obs上传URL
  getPaperUploadObsUrl: (data) => post("/api/paperinfo/", data),
  // popover内容详情
  addPrompt: (parameters) => post("/api/prompt/", parameters),
  getPrompts: (parameters) => get("/api/prompt/", parameters),
  getPromptTypes: (parameters) => get("/api/prompt/types/", parameters),
  // 查询文献模块的快捷方式
  getDocumentQuickTypes: (parameters) => get("/api/prompt/doc_selected/", parameters),
  getSelectedPrompt: (parameters) => get("/api/prompt/selected/", parameters),
  addScholar: (parameters) => post(`/api/paper/from_scholar/`, parameters),
  // 上传图片
  uploadMedia: (parameters) => upload("/api/usermedia/upload_media/", parameters),
  // 参数type 0: 登录 1: 绑定
  getUserToken: (parameters) => get("/api/usertoken/qrcode/", parameters),
  checkUserToken: (parameters) => get("/api/usertoken/check_qrcode/", parameters),
  // questionTypeCard 置顶和取消置顶
  toppingTypeCard: (parameters) => post("/api/userprompt/", parameters),
  untoppingTypeCard: (parameters) => post(`api/userprompt/remove/`, parameters),
  parseOutline: (parameters) => post("/api/data/parse_outline/", parameters),
  // 获取用户消息
  getUserMessage: (parameters) => get(`/api/message/my/`, parameters),
  // 消息已读
  batchChecked: (parameters) => post(`/api/message/patch_check/`, parameters),
  // 获取验证码
  getVerifyCode: (parameters) => post("/api/validcode/code/", parameters),
  // 手机号、邮箱登录
  loginByPhoneOrEmail: (parameters) => get("/api/usertoken/login_by_phone_or_email/", parameters),
  // 临时用户
  getTempUser: (parameters) => get("/api/usertoken/guest_login/", parameters),
  // 新用户指引
  newUserGuide: (id) => get(`/api/message/${id}/had_checked/ `, {}),
  // 更改消息状态
  changeMessageStatus: (id, parameters) => post(`/api/data/${id}/go_on/`, parameters),
  // 领取活动优惠券
  getCoupon: (parameters) => post("/api/discount/promotion/ ", parameters),
  // 常用语添加
  addInstruction: (parameters) => post(`/api/instruction/`, parameters),
  // 常用语列表
  getInstructionList: (parameters) => get(`/api/instruction/my/`, parameters),
  // 常用语编辑
  editInstruction: (id, parameters) => patch(`/api/instruction/${id}/`, parameters),
  // 常用语删除
  deleteInstruction: (id) => remove(`/api/instruction/${id}/`, {}),
  // 新用户是否领取优惠券
  newUserGetCoupon: (parameters) => get("/api/usertoken/has_discount/", parameters),
  // 生成PPT Token
  getPPTToken: (parameters) => get("/api/system/ppt_token/", parameters),
  // ppt扣点
  endPPT: (id, parameters) => post(`/api/data/${id}/end_ppt/`, parameters),
};
