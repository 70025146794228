import InputAreaIcons from "@/icons/InputAreaIcons";

function uploadConfig(quickReply) {
  switch (quickReply) {
    case "数据分析":
      return {
        accept: ".csv,.txt,.xlsx",
        limitSize: 10,
        title: ["上传表格数据", "数量：最多支持5个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 5,
        dropExtensions: ["csv", "txt", "xlsx"], // 拖拽上传文件类型限制
      };
    case "批处理":
      return {
        accept: ".xlsx",
        limitSize: 10,
        title: ["上传任务文件，每行一个", "数量：最多支持1个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 1,
        dropExtensions: ["xlsx"], // 拖拽上传文件类型限制
      };
    case "OFFICE密码破解":
      return {
        accept: ".xlsx,.xls,.doc,.docx,.ppt,.pptx",
        limitSize: 20,
        callback: true,
        title: ["上传OFFICE文件", "数量: 最多支持1个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 1,
        dropExtensions: ["xls", "doc", "xlsx", "docx", "ppt", "pptx"], // 拖拽上传文件类型限制
      };
    case "语音转文字":
      return {
        accept: ".mp3,.mp4,.mpeg,.mpga,.m4a,.wav,.webm",
        limitSize: 2,
        callback: true,
        title: ["上传音频", "数量：最多支持1个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 1,
        dropExtensions: ["mp3", "mp4", "mpeg", "mpga", "m4a", "wav", "webm"], // 拖拽上传文件类型限制
      };
    case "OCR图文识别":
    case "图片解释":
      return {
        accept: ".bmp,.jpg,.png,.tif,.gif,.webp,.jpeg",
        limitSize: 3,
        title: ["上传图片", "数量：最多支持5个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 5,
        dropExtensions: ["bmp", "jpg", "png", "tif", "gif", "webp", "jpeg"], // 拖拽上传文件类型限制
      };
    case "代码重现":
    case "图生论文":
    case "图表数字化":
      return {
        accept: ".bmp,.jpg,.png,.tif,.gif,.webp,.jpeg",
        limitSize: 3,
        title: ["上传图表", "数量：最多支持1个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 1,
        dropExtensions: ["bmp", "jpg", "png", "tif", "gif", "webp", "jpeg"], // 拖拽上传文件类型限制
      };
    case "AI视频":
      return {
        accept: ".bmp,.jpg,.png,.tif,.gif,.webp,.jpeg",
        limitSize: 3,
        title: ["初始图片，基于此图生成视频", "数量：最多支持1个，可以为空"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 1,
        dropExtensions: ["bmp", "jpg", "png", "tif", "gif", "webp", "jpeg"], // 拖拽上传文件类型限制
      };
    case "文章读写":
      return {
        accept: ".pdf,.docx,.doc,.txt",
        limitSize: 10,
        title: ["上传文档、数据", "数量：最多支持5个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 5,
        dropExtensions: ["pdf", "docx", "doc", "txt"], // 拖拽上传文件类型限制
      };
    case "文章重组":
    case "文章优化":
    case "生成PPT":
    case "绘制流程图":
    case "思维导图":
    case "整理表格":
      return {
        accept: ".pdf,.docx,.doc,.txt",
        limitSize: 10,
        title: ["上传文档", "数量：最多支持1个"],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 1,
        dropExtensions: ["pdf", "docx", "doc", "txt"], // 拖拽上传文件类型限制
      };
    case "灵知":
    case "开题报告":
    case "论文撰写":
    case "文献综述":
      return {
        accept: ".enw,.txt,.ciw,.ris",
        limitSize: 1,
        title: [
          "上传知网、万方、WOS等导出的带摘要的EndNote文件，系统基于上传文献撰写",
          "数量：非必须，最多支持2个，不上传自动从选择的网络库检索论文",
          "如果想基于PDF全文进行撰写，请在左侧切换到文献版块在目录内上传撰写",
        ],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 2,
        dropExtensions: ["enw", "txt", "ciw", "ris"], // 拖拽上传文件类型限制
      };
    case undefined:
    case null:
      return {
        accept: "*",
        limitSize: 10,
        title: [
          "上传图表、文献、文档、方案、笔记、数据、代码等等",
          "支持不超过10页的扫描版PDF",
          "不支持压缩包、可执行文件、视频等文件",
          "一个会话中只用上传一次",
          "数量：最多支持10个",
        ],
        icon: <InputAreaIcons type="upload" />,
        maxCount: 10,
        dropExtensions: [], // 拖拽上传文件类型限制为空不进行后缀判断
      };
    default:
      break;
  }
}

export function getUploadConfig(quickReply) {
  return uploadConfig(quickReply);
}
