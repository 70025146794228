import "katex/dist/katex.min.css";
import "@/themes/index.less";
import NormalMessage from "./NormalMessage";
import ResolveMessage from "./ResolveMessage";
import { useMatch } from "react-router-dom";

function MessageContentRender({
  msg,
  setText,
  onMessageTyping,
  messageList,
  onOutlineSend,
  setMessageList,
  onRefineSend,
}) {
  let { content, type, position } = msg;
  const includedQuestionTypes = ["论文撰写", "文献综述", "开题报告", "文章重组", "文章优化", "长文撰写"];
  const matchAtDocument = useMatch("/document");

  if (position === "left" && content?.message_type?.includes("scholar")) {
    try {
      JSON.parse(content.text);
    } catch (error) {
      content = { ...content, message_type: "text" };
    }
  }

  // 大纲编辑
  if (position === "left" && includedQuestionTypes.includes(content?.question_type) && content?.status === 5) {
    content = { ...content, message_type: "outline" };
  }

  if (matchAtDocument && position === "left" && content?.status === 5) {
    content = { ...content, message_type: "outline" };
  }

  // ppt编辑, ppt展示
  // if (position === "left" && content?.question_type === "生成PPT" && (content?.status === 5 || content?.status === 2)) {
  //   content = { ...content, message_type: "ppt" };
  // }

  switch (type) {
    case "normal-message":
      return (
        <NormalMessage
          messageType={content.message_type}
          text={content?.text}
          createdAt={content?.createdAt}
          setComposerText={setText}
          messageId={content?.messageId}
          position={position}
          isLast={content?.isLast}
          questionType={content?.question_type}
          placeholderType={content?.placeholder_type}
          status={content?.status}
          isContent={content?.isContent}
          config={content?.config}
          onMessageTyping={onMessageTyping}
          showButtons={content?.showButtons}
          messageList={messageList}
          onOutlineSend={onOutlineSend}
          setMessageList={setMessageList}
          onRefineSend={onRefineSend}
        />
      );
    case "resolve-message":
      return (
        <ResolveMessage
          key={content?.messageId}
          setComposerText={setText}
          messageId={content?.messageId}
        />
      );
    default:
      return null;
  }
}

export default MessageContentRender;
