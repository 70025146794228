import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Descriptions, Dropdown, Typography } from "antd";
import { FilePdfOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import cls from "classnames";
import useScreenSize from "@/hooks/useScreenSize";

const { Paragraph } = Typography;

function CNKICard({ props, index }) {
  const { abstract, author, publicationTypes, keywords, year, journal, title, url } = props;
  const dispatch = useDispatch();
  const { middle } = useScreenSize();
  const [uploading, setUploading] = useState(false);
  const { model } = useSelector((state) => state.token);

  const addHandler = useCallback(() => {
    dispatch.chat.updateCNKI({
      url,
      title,
      downloaded: true,
    });
  }, [dispatch, title, url]);

  const linkHandler = useCallback(() => {
    window.open(url, "_blank");
  }, [url]);

  const importHandler = useCallback(() => {
    if (uploading) return;
    setUploading(true);
    const results = dispatch.chat.addScholar({
      paper_url: url,
      title,
      model,
      db: "CNKI",
      info: props,
    });
    results.then((res) => setUploading(res));
  }, [dispatch, props, uploading, url, title, setUploading, model]);

  return (
    <div className={cls("cnkiCard", { widthStyle: !middle })}>
      <Descriptions
        column={2}
        title={
          <Paragraph
            className="title"
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: "更多",
              onExpand: (e) => e.stopPropagation(),
            }}
            title={`${index}. ${title}`}
            onClick={() => linkHandler()}
          >
            {index}. {title}
          </Paragraph>
        }
        // extra={
        //   <Dropdown.Button
        //     menu={{
        //       items: [
        //         {
        //           label: (
        //             <Button
        //               className={cls({ disabled: uploading })}
        //               type="text"
        //               icon={uploading ? <LoadingOutlined /> : <SendOutlined />}
        //               onClick={importHandler}
        //             >
        //               导入文献分析
        //             </Button>
        //           ),
        //           key: "1",
        //         },
        //       ],
        //     }}
        //     getPopupContainer={(node) => node}
        //     onClick={addHandler}
        //   >
        //     <FilePdfOutlined /> PDF下载
        //   </Dropdown.Button>
        // }
      >
        <Descriptions.Item label={<div className="label-name">作者</div>}>{author || "无"}</Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">来源</div>}>{journal || "无"}</Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">发表年份</div>}>{year || "无"}</Descriptions.Item>
        <Descriptions.Item label={<div className="label-name">数据库</div>}>
          {publicationTypes || "无"}
        </Descriptions.Item>
        <Descriptions.Item
          label={<div className="label-name">摘要</div>}
          span={2}
          className="abstract"
        >
          <Paragraph
            ellipsis={{
              rows: 3,
              expandable: true,
              symbol: "更多",
            }}
            title={abstract}
          >
            {abstract || "无"}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          label={<div className="label-name">关键词</div>}
          span={2}
        >
          {keywords || "无"}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
}

export default CNKICard;
