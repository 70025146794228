import styles from "./index.module.less";
import ActionIcons from "@/icons/ActionIcons";
import { Button } from "antd";
import imageUrl from "@/assets/bubbleBtnBg.png";
import { useSelector } from "react-redux";
import BubbleToolbar from "../BubbleToolbar";

function VideoBubble({
  questionType,
  text,
  createdAt,
  status,
  finished,
  messageId,
  setComposerText,
  position,
  isLast,
  isContent,
}) {
  const { checking } = useSelector((state) => state.user);
  function handleDownload() {
    // TODO: 下载视频
    const videoUrl = text;
    const fileName = "video.mp4";
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    const xhr = new XMLHttpRequest();
    xhr.open("GET", videoUrl, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      if (xhr.status === 200) {
        const videoBlob = xhr.response;
        const url = window.URL.createObjectURL(videoBlob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
      }
    };
    xhr.send();
  }
  return (
    <div className={styles.VideoBubble}>
      <video
        controls={true}
        controlsList="nodownload"
        autoPlay={false}
        playsInline={true}
        loop={false}
        muted={true}
        width={500}
        height={280}
      >
        <source
          src={text}
          type="video/mp4"
        />
      </video>
      {!checking && finished && !isContent && (
        <BubbleToolbar
          text={text}
          createdAt={createdAt}
          setComposerText={setComposerText}
          messageId={messageId}
          position={position}
          isLast={isLast}
          isContent={isContent}
          isBasic={true}
          status={status}
        />
      )}
      <div className="video-buttons">
        <Button
          type="primary"
          icon={<ActionIcons type="download" />}
          className="download-button"
          onClick={handleDownload}
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
          }}
        >
          下载视频
        </Button>
      </div>
    </div>
  );
}

export default VideoBubble;
