export const browser = {
  state: {
    isChrome: undefined,
    isFirefox: undefined,
    isMac: undefined,
  }, // initial state
  reducers: {
    // handle state changes with pure functions
    update(state, payload) {
      return {
        ...state,
        ...payload,
      };
    },
  },
  effects: (dispatch) => ({}),
};
