import { Modal, Button, message, Space, Spin, Progress } from "antd";
import styles from "./index.module.less";
import { useState, useEffect, useCallback, Fragment, useRef } from "react";
import cls from "classnames";
import GeneratePage from "./GeneratePage";
import ToolsIcons from "@/icons/ToolsIcons";

function PPTModal({ show, setShowModal, pptToken, mdData, messageId, status, historyPptInfo }) {
  const [templateOptions, setTemplateOptions] = useState(null);
  const [templateId, setTemplateId] = useState("");
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tip, setTip] = useState("加载中...");
  const [currentPage, setCurrentPage] = useState(1);
  const [percent, setPercent] = useState(100);
  const [historyPptId, setHistoryPptId] = useState("");
  const [filters, setFilters] = useState({
    type: 1,
    category: "",
    style: "",
    themeColor: "",
  });
  const headers = {
    token: pptToken,
    "Content-Type": "application/json",
  };
  const generateRef = useRef(null);
  useEffect(() => {
    if (show && status === 5 && pptToken) {
      setTip("加载中...");
      getTenplateOptions();
      loadTemplates();
    }
    if (show && status === 2 && pptToken) {
      setCurrentPage(2);
      setHistoryPptId(historyPptInfo[messageId].id);
    }
  }, [show]);

  useEffect(() => {
    if (show && pptToken) {
      loadTemplates();
    }
  }, [filters]);

  const getTenplateOptions = useCallback(async () => {
    const url = "https://docmee.cn/api/ppt/template/options";
    const resp = await fetch(url, {
      method: "GET",
      headers,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code !== 0) {
          message.warning(`获取菜单异常：${data.message}`);
        }
        setTemplateOptions(data.data || null);
      })
      .catch((err) => {
        message.warning(`获取菜单异常：${err.message}`);
      });
  }, [pptToken]);

  const loadTemplates = useCallback(async () => {
    setLoading(true);
    const url = "https://docmee.cn/api/ppt/templates";
    const resp = await fetch(url, {
      method: "POST",
      headers,
      body: JSON.stringify({ page: 1, size: 50, filters }),
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.code !== 0) {
          setLoading(false);
          message.warning(`获取模板异常：${data.message}`);
        }
        setLoading(false);
        setTemplates(data.data || []);
      })
      .catch((err) => {
        setLoading(false);
        message.warning(`获取模板异常：${err.message}`);
      });
  }, [pptToken, filters]);

  const selectTemplate = useCallback(
    (template) => {
      setTemplateId(template.id);
    },
    [pptToken, mdData]
  );

  const handleCancel = () => {
    setCurrentPage(1);
    setFilters({
      type: 1,
      category: "",
      style: "",
      themeColor: "",
    });
    setTemplateId(null);
    setShowModal(false);
  };

  // 选项
  const optionsComponent = (
    <div className="template-options">
      <div className="template-option">
        <div className="name">模版类别:</div>
        <div className="option-list">
          {templateOptions?.category.map((item, index) => {
            return (
              <div
                className={cls("option-item", {
                  active: filters.category === item.value,
                })}
                key={`${index}-${item.value}`}
                onClick={() => {
                  setFilters({ ...filters, category: item.value });
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="template-option">
        <div className="name">设计风格:</div>
        <div className="option-list">
          {templateOptions?.style.map((item, index) => {
            return (
              <div
                className={cls("option-item", {
                  active: filters.style === item.value,
                })}
                key={`${index}-${item.value}`}
                onClick={() => {
                  setFilters({ ...filters, style: item.value });
                }}
              >
                {item.name}
              </div>
            );
          })}
        </div>
      </div>
      <div className="template-option">
        <div className="name">主题颜色:</div>
        <div className="theme-color-list">
          {templateOptions?.themeColor.map((item, index) => {
            return (
              <Fragment key={`${index}-${item.value}`}>
                {item.name === "全部" ? (
                  <div
                    // className="theme-color-item theme-color-item-all"
                    className={cls("theme-color-item theme-color-item-all", {
                      active: filters.themeColor === item.value,
                    })}
                    key={`${index}-${item.value}`}
                    onClick={() => {
                      setFilters({ ...filters, themeColor: item.value });
                    }}
                  ></div>
                ) : (
                  <div
                    className={cls("theme-color-item", {
                      active: filters.themeColor === item.value,
                    })}
                    key={`${index}-${item.value}`}
                    style={{ backgroundColor: item.value }}
                    onClick={() => {
                      setFilters({ ...filters, themeColor: item.value });
                    }}
                  ></div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
  return (
    <Modal
      open={show}
      destroyOnClose={true}
      wrapClassName={styles.pptModal}
      width="100vw"
      style={{
        maxWidth: "100vw",
        top: 0,
        paddingBottom: 0,
      }}
      bodyStyle={{
        height: "100vh",
        overflowY: "hidden",
      }}
      footer={null}
      onCancel={handleCancel}
      closable={false}
    >
      <Fragment>
        <div className="ppt-modal-header">
          <div className="progress-bar">
            {currentPage === 2 && !historyPptId ? (
              <Progress
                percent={percent}
                status="active"
                strokeColor={{
                  "0%": "#ED1A1A",
                  "20%": "#D616F7",
                  "50%": "#5918F0",
                  "100%": "#FF8000",
                }}
                strokeWidth={15}
              />
            ) : null}
          </div>
          <div
            onClick={handleCancel}
            className="close-btn"
          >
            <ToolsIcons type="pptClose" />
          </div>
        </div>
        {currentPage === 1 ? (
          <div className={styles.templatePage}>
            <div className="page-header">
              <span>选择一套模板，开始创建 PPT</span>
            </div>
            <div className="page-content">
              {loading ? (
                <div className="spin">
                  <Spin tip={tip}></Spin>
                </div>
              ) : (
                <>
                  <div className="template-list">
                    {templates.map((template) => (
                      <div
                        className={template.id === templateId ? "template templateSelected" : "template"}
                        key={template.id}
                        onClick={() => selectTemplate(template)}
                      >
                        <img src={`${template.coverUrl}?token=${pptToken}`} />
                      </div>
                    ))}
                  </div>
                </>
              )}
              {optionsComponent}
            </div>
            <div className="page-footer">
              <Space>
                <Button
                  onClick={handleCancel}
                  style={{
                    borderRadius: "10px",
                    background: "rgb(229, 229, 229)",
                    border: "none",
                    color: "rgba(0, 0, 0, 0.85)",
                  }}
                >
                  取消
                </Button>
                <Button
                  type="primary"
                  style={{
                    borderRadius: "10px",
                    background: "rgb(37, 111, 255)",
                  }}
                  onClick={() => {
                    setCurrentPage(2);
                  }}
                >
                  生成PPT
                </Button>
              </Space>
            </div>
          </div>
        ) : (
          <GeneratePage
            currentPage={currentPage}
            pptToken={pptToken}
            templateId={templateId}
            outline={mdData}
            dataUrl={""}
            setPercent={setPercent}
            messageId={messageId}
            historyPptId={historyPptId}
            ref={generateRef}
          />
        )}
      </Fragment>
    </Modal>
  );
}

export default PPTModal;
