import { Modal, Input, Space, Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import styles from "./index.module.less";
import { useState, useEffect, useCallback } from "react";
import { data as dataService } from "@/services";
import { useRequest } from "ahooks";
import { feedback } from "@/utils/index";

const PhraseEditModal = ({ type, open, setOpen, getList, editData }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [titleTop, setTitleTop] = useState("0px");
  const [instruction, setInstruction] = useState("");
  const { TextArea } = Input;

  useEffect(() => {
    if (editData) {
      setTitle(editData.title);
      setInstruction(editData.instruction);
    }
  }, [open]);

  const { run: addPhrase } = useRequest(() => dataService.addInstruction({ title, instruction }), {
    manual: true,
    onSuccess: (res) => {
      if (res.status === "OK") {
        message.success("添加成功");
        setOpen(false);
        getList();
      }
    },
    onError: (err) => {
      feedback(err.message || "添加失败");
    },
  });

  const updatePhrase = useCallback(async (id, title, instruction) => {
    const res = await dataService.editInstruction(id, { title, instruction });
    if (res.status === "OK") {
      message.success("修改成功");
      setOpen(false);
      getList();
    } else {
      feedback(res.data || "修改失败");
    }
  }, []);

  const onOk = useCallback(() => {
    if (type === "add") {
      addPhrase();
    } else {
      updatePhrase(editData.id, title, instruction);
    }
  });

  const onCancel = useCallback((e) => {
    setOpen(false);
    setTitle("");
    setInstruction("");
    e.preventDefault();
    e.stopPropagation();
  });

  return (
    <Modal
      title={type === "add" ? "添加常用语" : "编辑常用语"}
      wrapClassName={styles.phraseEditModal}
      width={600}
      bodyStyle={{ height: "450px" }}
      open={open}
      destroyOnClose={true}
      maskClosable={false}
      centered
      onCancel={onCancel}
      footer={
        <div className="PhraseEditFooter">
          <Space>
            <Button
              shape="round"
              onClick={onCancel}
            >
              取消
            </Button>
            {type === "add" ? (
              <Button
                shape="round"
                disabled={!title?.trim() || !instruction?.trim()}
                onClick={onOk}
                key={"add"}
              >
                添加
              </Button>
            ) : (
              <Button
                shape="round"
                onClick={onOk}
                key={"edit"}
                disabled={
                  (editData.title === title && editData.instruction === instruction) ||
                  !title?.trim() ||
                  !instruction?.trim()
                }
              >
                确定
              </Button>
            )}
          </Space>
        </div>
      }
    >
      <div className="PhraseEditContainer">
        <div className="PhraseEditInputWrapper">
          <span>标签：</span>
          <Input
            className="PhraseEditInput"
            placeholder="给你的常用语取个响亮的名字吧~~"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            bordered={false}
          />
        </div>
        <div
          className="PhraseEditInputWrapper"
          style={{ height: "91%" }}
        >
          <span style={{ top: titleTop }}>内容：</span>
          <TextArea
            className="PhraseEditTextarea"
            placeholder="请输入常用内容，例如：作为一名中文学术论文写作改进助理，你的任务是改进所提供文本的拼写、语法、清晰、简洁和整体可读性，同时分解长句，减少重复，并提供改进建议。请只提供文本的更正版本，避免包括解释。"
            style={{ height: "100%", resize: "none" }}
            value={instruction}
            onChange={(e) => {
              setInstruction(e.target.value);
            }}
            onScroll={(e) => setTitleTop(`${-1 * e.target.scrollTop}px`)}
            bordered={false}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PhraseEditModal;
