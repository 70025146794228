import { useState, useCallback, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSessionStorageState, useRequest } from "ahooks";
import { useLocation } from "react-router-dom";
import LeftMenu from "@/components/common/LeftMenu";
import BottomMenu from "@/components/common/MobileBottomMenu";
import WindowHeader from "@/components/common/WindowHeader";
import WindowBody from "@/components/chat/WindowBody";
import useScreenSize from "@/hooks/useScreenSize";
import useLayoutInit from "./layoutInit";
import styles from "./index.module.less";
import Tour from "@/components/common/Tour";
import steps from "./config";

function ChatLayout() {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { middle } = useScreenSize();
  // const [showSider, setShowSider] = useState(state?.show || middle); //这个state.show是windowHeader会话与文献相互切换时传递来的，目的是为了在小屏时相互切换，sider不会关闭
  const [showSider, setShowSider] = useState(localStorage.getItem("showSider") === "0" || !middle ? false : true);
  const { chatPromptVersion } = useSelector((state) => state.app);
  const { userGuide } = useSelector((state) => state.user);

  const [promptListCached, setPromptListCached] = useSessionStorageState("chat-prompt-list", {
    defaultValue: null,
  });

  const { getSelectedPrompt, getSelectedPromptForce } = useLayoutInit({
    dispatch,
    promptListCached,
    setPromptListCached,
  });

  const siderHandler = useCallback(
    (v) => {
      localStorage.setItem("showSider", !v ? 0 : 1);
      setShowSider(v);
    },
    [setShowSider]
  );

  useEffect(() => {
    setPromptListCached(null);
    getSelectedPromptForce();
  }, [chatPromptVersion]);

  useEffect(() => {
    getSelectedPrompt();
  }, []);

  return (
    <div className={styles.chatLayout}>
      {middle && (
        <LeftMenu
          showSider={showSider}
          siderHandler={siderHandler}
        />
      )}
      <div
        className={styles.chatBody}
        style={{ width: middle ? "calc(100% - 59px)" : "100%" }}
      >
        <WindowBody
          showSider={showSider}
          siderHandler={siderHandler}
        />
        {!middle && <BottomMenu />}
      </div>
      <Tour
        run={userGuide}
        steps={steps}
      />
    </div>
  );
}

export default ChatLayout;
