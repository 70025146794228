export const USAGE_RULE =
  "使用 GPT4o mini, Claude Haiku 模型时，在对话中每次问答消耗 1 点，在文献中全文翻译、全文润色每 1000 字消耗 2 点，对文献提问每次消耗 2 点。 使用 Gemini 消耗为 GPT4o mini 的 20 倍，Claude Sonnet, GPT4o 为 20 倍，Opus 为 30 倍，o1 为 100 倍，登录后GPT4o mini、文心一言免费(除文献)。";

export const QUESTION_ERROR = {
  请新建会话: "total-length",
  "额度不足，请点击会员按钮续费或明天再接着使用": "no-times",
  登录设备数量过多: "just-notice",
};

export const USER_TYPE_DESCRIPTION = {
  点卡用户: "",
  企业用户: "",
  月卡用户: "单月 GPT4o 800 次问答，不能使用Opus, o1模型，其余无限制",
  畅享月卡: "GPT4o mini, Haiku, 文心一言 模型无限制，不能使用Sonnet, Opus, GPT4o, Gemini, o1模型",
  "畅享月卡(PLUS)": "不能使用GPT4o, Opus, o1模型，其余模型无限制",
  高级月卡: "单月赠送o1模型100次问答，其余模型无限制",
  高级用户: "GPT4o等所有模型无限制",
};
