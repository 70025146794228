import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Avatar, Segmented, Space, Tooltip, message, Popover } from "antd";
import { useTimeout } from "ahooks";
import { MODEL_ICONS, MODEL_FEATURES, MODEL_TIPS, MODEL_LIST, MODEL_FEATURES_LIST } from "@/constants/model";
import { TagTwoTone } from "@ant-design/icons";

function ModelSegmented() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { model } = useSelector((state) => state.token);

  const [id, setId] = useState("segmented");

  useTimeout(() => {
    setId();
  }, 3000);

  const changeHandler = useCallback(
    (model) => {
      message.success({
        content: MODEL_TIPS[model],
        style: { marginLeft: 300, marginTop: 42 },
      });
      dispatch.token.update({ model });
    },
    [pathname]
  );

  return (
    <div
      style={{
        width: 900,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Segmented
        className="tour-model"
        id={id}
        onChange={changeHandler}
        value={model}
        options={MODEL_LIST.map((_model) => {
          return {
            label: (
              <Popover
                content={
                  <div className="model-tips">
                    {MODEL_FEATURES_LIST[_model.value]?.map((item, index) => {
                      return (
                        <div
                          key={`${index}-${_model.value}`}
                          className="model-tips-item"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                            fontSize: 13,
                          }}
                        >
                          <TagTwoTone />
                          <span>{item.description}</span>
                        </div>
                      );
                    })}
                  </div>
                }
                overlayInnerStyle={{
                  borderRadius: 10,
                }}
              >
                <Space size={4}>
                  <Avatar
                    size={24}
                    src={MODEL_ICONS[_model.value]}
                  />
                  <div>{_model.label}</div>
                </Space>
              </Popover>
              // <Tooltip title={MODEL_FEATURES[_model.value]}>
              //   <Space size={4}>
              //     <Avatar
              //       size={24}
              //       src={MODEL_ICONS[_model.value]}
              //     />
              //     <div>{_model.label}</div>
              //   </Space>
              // </Tooltip>
            ),
            value: _model.value,
          };
        })}
      />
    </div>
  );
}

export default ModelSegmented;
