import { useCallback } from "react";
import { Button, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import InputAreaIcons from "@/icons/InputAreaIcons";
import classNames from "classnames";
import styles from "./index.module.less";
import CustomTip from "@/components/common/CustomTip";

function OnlineButton() {
  const dispatch = useDispatch();
  const { online } = useSelector((state) => state.chat);

  const onlineHandler = useCallback(() => {
    dispatch.chat.update({ online: !online });
  }, [online]);

  return (
    <CustomTip
      content={
        online
          ? ["关闭联网"]
          : [
              "点击后开启联网搜索，从互联网检索信息回答",
              "如需检索特定网站内容，请在问题最后添加 site:example.com",
              "如需对具体网页分析，请关闭联网直接在输入框中输入网址以及分析内容",
              "联网模式下无法使用上传文件功能",
            ]
      }
    >
      <Button
        size="large"
        type="text"
        icon={
          <span className={classNames([styles.internetBtn], online ? styles.rotating : null)}>
            <InputAreaIcons type="internet" />
          </span>
        }
        onClick={onlineHandler}
      />
    </CustomTip>
  );
}

export default OnlineButton;
