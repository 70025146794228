import cls from "classnames";
import EditorInput from "../EditorInput";
import styles from "./index.module.less";
import { useEffect } from "react";

const EditorHeader = ({ title, error = [], lang, updateText }) => {
  return (
    <div className={cls(styles.editorHeader, "editorHeader")}>
      <EditorInput
        tagType="h3"
        label="标题："
        placeholder="请输入PPT标题"
        value={title}
        required={true}
        invalid={error.includes("title")}
        onChange={({ text }) => updateText({ text, type: "title" })}
      />
    </div>
  );
};

export default EditorHeader;
