import EditorHeader from "./EditorHeader";
import EditorParagraph from "./EditorParagraph";
import styles from "./index.module.less";
import "./style.less";
import { useEffect, ref, useCallback } from "react";
import { has } from "lodash-es";
import { EditorIcons } from "./EditorIcons";

const EditorContent = ({ pptData, error = [], updateText, addNode, deleteNode, addContent, deleteContent }) => {
  // let nodeNo = 1;
  // useEffect(() => {
  //   transformData(pptData);
  // }, [pptData]);

  // function transformData(node) {
  //   node.nodeNo = nodeNo++;
  //   if (node.nodes) {
  //     node.nodes.forEach((child) => {
  //       if (has(child, "nodeText")) return;
  //       transformData(child);
  //     });
  //   }
  // }

  return (
    <div className={styles.EditorContent}>
      <div className="ppt-pages">
        <EditorIcons type="pptIcon" />
        <div className="ppt-pages-title">幻灯片{pptData.nodeNo}:封面</div>
      </div>
      <EditorHeader
        error={error}
        title={pptData.title}
        updateText={updateText}
      />
      {(pptData.nodes || []).map(({ nodeName, nodeText, nodes = [], nodeNo }, index) => {
        return (
          <EditorParagraph
            error={error}
            deleteDisabled={pptData.nodes.length <= 1}
            updateText={updateText}
            addNode={addNode}
            addChildNode={({ level1Index }) => addNode({ level1Index, level2Index: nodes.length - 1 })}
            deleteNode={deleteNode}
            key={index}
            level1={index}
            nodeName={nodeName}
            nodeText={nodeText}
            nodeNo={nodeNo}
            childSize={pptData.nodes.length}
            showText={nodes.length === 0}
            addContent={addContent}
            deleteContent={deleteContent}
          >
            {nodes.map(
              (
                { nodeName: childNodeName, nodeText: childNodeText, nodes: childNodes, nodeNo: childNodeNo },
                childIndex
              ) => {
                return (
                  <EditorParagraph
                    error={error}
                    updateText={updateText}
                    addNode={addNode}
                    addChildNode={({ level1Index, level2Index }) =>
                      addNode({ level1Index, level2Index, level3Index: childNodes.length - 1 })
                    }
                    deleteNode={deleteNode}
                    key={`${index}-${childIndex}`}
                    level1={index}
                    level2={childIndex}
                    nodeName={childNodeName}
                    nodeText={childNodeText}
                    nodeNo={childNodeNo}
                    childSize={nodes.length}
                    showText={childNodes.length === 0}
                    addContent={addContent}
                    deleteContent={deleteContent}
                  >
                    {childNodes.map(
                      ({ nodeName: grandChildNodeName, nodeText: grandChildNodeText }, grandChildIndex) => {
                        return (
                          <EditorParagraph
                            error={error}
                            updateText={updateText}
                            addNode={addNode}
                            deleteNode={deleteNode}
                            key={`${index}-${childIndex}-${grandChildIndex}`}
                            level1={index}
                            level2={childIndex}
                            level3={grandChildIndex}
                            nodeName={grandChildNodeName}
                            nodeText={grandChildNodeText}
                            childSize={childNodes.length}
                            showText={true}
                            addContent={addContent}
                            deleteContent={deleteContent}
                          ></EditorParagraph>
                        );
                      }
                    )}
                  </EditorParagraph>
                );
              }
            )}
          </EditorParagraph>
        );
      })}
    </div>
  );
};

export default EditorContent;
