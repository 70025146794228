import styles from "./index.module.less";
import { MODEL_ICONS } from "@/constants/model";
import { Avatar } from "antd";
function PointsPopContent({ tokenInfo }) {
  const list = [
    {
      label: `文心一言对话免费用${tokenInfo?.openid ? "" : "（登录后）"}`,
      icon: "ERNIE_TURBO",
      pointColor: !tokenInfo?.openid,
    },
    {
      label: `GPT4o mini对话免费用${tokenInfo?.openid ? "" : "（登录后）"}`,
      icon: "GPT4_MINI",
      pointColor: !tokenInfo?.openid,
    },
    {
      label: "Claude Haiku 1点/次",
      icon: "CLAUDE_V3_HAIKU",
    },
    {
      label: "Claude Sonnet 20点/次",
      icon: "CLAUDE_V3_SONNET",
    },
    {
      label: "Claude Opus 30点/次",
      icon: "CLAUDE_V3_OPUS",
    },
    {
      label: "Google Gemini 20点/次",
      icon: "GEMINI_FLASH",
    },
    {
      label: "GPT4o 20点/次",
      icon: "GPT4",
    },
    {
      label: "OpenAI o1 100点/次",
      icon: "O1",
    },
  ];
  return (
    <div className={styles.PointsPopContent}>
      {list.map((item) => (
        <div
          className="item"
          key={item.icon}
          style={{ color: item.pointColor ? "#60a5fa" : "" }}
        >
          <Avatar
            size={18}
            src={MODEL_ICONS[item.icon]}
          />
          <div className={styles.itemText}>{item.label}</div>
        </div>
      ))}
      <div>次指普通对话,插件根据对应标准扣除</div>
    </div>
  );
}

export default PointsPopContent;
