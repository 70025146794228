import { Fragment } from "react";
import { Button, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import InputAreaIcons from "@/icons/InputAreaIcons/index";
import CustomTip from "@/components/common/CustomTip";

function PhraseBookButton({ phraseBtnRef }) {
  const { phraseStatus: status } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <CustomTip content={["常用语管理", "一键选择常用语"]}>
        <Button
          ref={phraseBtnRef}
          size="large"
          type="text"
          icon={<InputAreaIcons type="express" />}
          onClick={() => {
            dispatch.chat.update({
              phraseStatus: !status,
            });
          }}
        />
      </CustomTip>
      {/* <Tooltip
        placement="top"
        title={"选择常用语"}
      >
        <Button
          ref={phraseBtnRef}
          size="large"
          type="text"
          icon={<InputAreaIcons type="express" />}
          onClick={() => {
            dispatch.chat.update({
              phraseStatus: !status,
            });
          }}
        />
      </Tooltip> */}
    </Fragment>
  );
}

export default PhraseBookButton;
