import { useSelector } from "react-redux";
import { Fragment, useRef, useState, useCallback, useEffect } from "react";
import { Popover, Modal, Image } from "antd";
import LeftBottomIcons from "@/icons/LeftBottomIcons";
import styles from "./index.module.less";
import { data as dataService } from "@/services/data";
import { feedback } from "@/utils/index";
import { useRequest } from "ahooks";
import cls from "classnames";
import { CloseCircleFilled } from "@ant-design/icons";

function NewUserButton() {
  const { tokenInfo, tokenLoading } = useSelector((state) => state.user);
  const { phone, email } = tokenInfo;
  const [openModal, setOpenModal] = useState(false);
  const isReceived = useRef(false); // 初始化判断是否已领取
  const [getCoupon, setGetCoupon] = useState(false);

  const { loading } = useRequest(() => dataService.newUserGetCoupon(), {
    onSuccess: (res) => {
      if (res) {
        isReceived.current = res.data;
      }
    },
    onError: (error) => {
      feedback(error);
    },
  });

  const { run: handlerCoupon } = useRequest(() => dataService.getCoupon(), {
    manual: true,
    onSuccess: () => {
      setGetCoupon(true);
    },
    onError: (err) => {
      feedback(err.data);
      setGetCoupon(false);
    },
  });

  const handleCancel = useCallback((e) => {
    setOpenModal(false);
  }, []);

  const clickHandler = useCallback(() => {
    window.open("/console/vip", "_blank");
  }, []);

  const component = (
    <div className={styles.newUserContainer}>
      <div
        className={cls("newuser-item", {
          "newuser-item-active": !isReceived.current,
          "newuser-item-disabled": isReceived.current,
        })}
      >
        <div className="left">
          <LeftBottomIcons type="coupon" />
          <div className="info">
            <div
              className={cls("title", {
                "title-active": !isReceived.current,
                "title-disabled": isReceived.current,
              })}
            >
              98折新人券
            </div>
            <div className="subtitle">全场0元起用</div>
          </div>
        </div>
        <div
          className={cls("btn", {
            "btn-disabled": isReceived.current,
          })}
          onClick={() => {
            setOpenModal(true);
            handlerCoupon();
          }}
        >
          {isReceived.current ? "已领取" : "立即领取"}
        </div>
      </div>
      <div
        className={cls("newuser-item", {
          "newuser-item-active": !phone,
          "newuser-item-disabled": phone,
        })}
      >
        <div className="left">
          <LeftBottomIcons type="phone" />
          <div className="info">
            <div
              className={cls("title", {
                "title-active": !phone,
                "title-disabled": phone,
              })}
            >
              100点积分
            </div>
            <div className="subtitle">首次绑定手机</div>
          </div>
        </div>
        <div
          className={cls("btn", {
            "btn-disabled": phone,
          })}
          onClick={() => {
            window.open("/console/", "_blank");
          }}
        >
          {phone ? "已绑定" : "立即绑定"}
        </div>
      </div>
      <div
        className={cls("newuser-item", {
          "newuser-item-active": !email,
          "newuser-item-disabled": email,
        })}
      >
        <div className="left">
          <LeftBottomIcons type="email" />
          <div className="info">
            <div
              className={cls("title", {
                "title-active": !email,
                "title-disabled": email,
              })}
            >
              100点积分
            </div>
            <div className="subtitle">首次绑定邮箱</div>
          </div>
        </div>
        <div
          className={cls("btn", {
            "btn-disabled": email,
          })}
          onClick={() => {
            window.open("/console/", "_blank");
          }}
        >
          {email ? "已绑定" : "立即绑定"}
        </div>
      </div>
    </div>
  );
  return (
    <>
      {(!isReceived.current || !email || !phone) && (
        <div
          className="leftMenuBottom-newUser gift-button"
          style={{ visibility: tokenLoading ? "hidden" : "visible" }}
        >
          <Popover
            placement="right"
            content={component}
            overlayClassName={styles.newUserPopover}
          >
            <span>
              <LeftBottomIcons type="newGift" />
            </span>
          </Popover>
          <Modal
            open={openModal}
            onCancel={handleCancel}
            footer={null}
            mask={true}
            maskClosable={false}
            wrapClassName={styles.specialModal}
            width={700}
            closeIcon={<CloseCircleFilled />}
            centered={true}
            style={{ top: "5px" }}
          >
            <Image
              src={getCoupon ? "/specialuse.png" : "/specialget.png"}
              style={{ cursor: "pointer" }}
              preview={false}
              onClick={clickHandler}
            />
          </Modal>
        </div>
      )}
    </>
  );
}

export default NewUserButton;
