import EditorContent from "./EditorContent";
import { useState, useCallback, useLayoutEffect, forwardRef, useImperativeHandle, useEffect, useRef } from "react";
import { has, cloneDeep } from "lodash-es";
import { useSelector, useDispatch } from "react-redux";
import { useRequest, useHover } from "ahooks";
import { feedback } from "@/utils/index";
import { data as dataService } from "@/services";
import { PPT_EMPTY, formatMd, parsePPTData } from "./template";
import "./style.less";
import { Button, Image, Skeleton, Spin } from "antd";
import ActionIcons from "@/icons/ActionIcons";
import PPTModal from "./GeneratePPT/PPTModal";
import BubbleToolbar from "../BubbleToolbar";
import { EyeOutlined } from "@ant-design/icons";
import imageUrl from "@/assets/bubbleBtnBg.png";

const PPTBubble = forwardRef((props, ref) => {
  const { questionType, text, createdAt, status, finished, messageId, setComposerText, position, isLast, isContent } =
    props;
  const [pptData, setPptData] = useState(PPT_EMPTY);
  const [error, setError] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pptToken, setPptToken] = useState("");
  const [mdData, setMdData] = useState("");
  const [historyPptInfo, setHistoryPptInfo] = useState(null);
  const { checking } = useSelector((state) => state.user);
  const imgRef = useRef(null);
  const hoverImg = useHover(imgRef);
  const dispatch = useDispatch();
  let nodeNo = 1;

  useImperativeHandle(ref, () => {
    return {};
  });

  useLayoutEffect(() => {
    if (status === 5 && text) {
      const data = parsePPTData(text);
      if (data) {
        nodeNo = 1;
        transformData(data);
        setPptData(data);
      }
    }
    if (status === 2) {
      getPPTToken();
    }
  }, []);

  useEffect(() => {
    if (pptToken) {
      getPPTToken();
    }
  }, [status]);

  useEffect(() => {
    if (!showModal) {
      dispatch.chat.version();
      // setPptToken("");
    }
  }, [showModal]);

  function transformData(node) {
    node.nodeNo = nodeNo++;
    if (node.nodes) {
      node.nodes.forEach((child) => {
        if (has(child, "nodeText")) return;
        transformData(child);
      });
    }
  }

  const updateText = useCallback(
    ({ type, text, level1Index, level2Index, level3Index, nodeTextIndex }) => {
      const newPptData = cloneDeep(pptData);
      if (["title"].includes(type)) {
        newPptData[type] = text || "";
        setPptData(newPptData);
      }
      if (!level1Index && level1Index !== 0) {
        return;
      }
      newPptData.nodes = newPptData.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        if (type === "nodeText") {
          newPptData.nodes[level1Index][type][nodeTextIndex] = text;
        } else {
          newPptData.nodes[level1Index][type] = text;
        }
        setPptData(newPptData);
        return;
      }
      // 只有level1、level2
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        newPptData.nodes[level1Index].nodes = newPptData.nodes[level1Index].nodes || [];
        // newPptData.nodes[level1Index].nodes[level2Index][type] = text;
        if (type === "nodeText") {
          newPptData.nodes[level1Index].nodes[level2Index][type][nodeTextIndex] = text;
        } else {
          newPptData.nodes[level1Index].nodes[level2Index][type] = text;
        }
        setPptData(newPptData);
        return;
      }
      // 只有level1、level2、level3
      newPptData.nodes[level1Index].nodes[level2Index].nodes =
        newPptData.nodes[level1Index].nodes[level2Index].nodes || [];
      // newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index][type] = text;
      if (type === "nodeText") {
        newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index][type][nodeTextIndex] = text;
      } else {
        newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index][type] = text;
      }
      setPptData(newPptData);
    },
    [pptData, setPptData]
  );

  const addNode = useCallback(
    ({ level1Index, level2Index, level3Index }) => {
      const newPptData = cloneDeep(pptData);
      newPptData.nodes = newPptData.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        const nodes = [...newPptData.nodes];
        nodes.splice(level1Index + 1, 0, {
          nodeName: "",
          nodes: [],
        });
        newPptData.nodes = nodes;
        nodeNo = 1;
        transformData(newPptData);
        setPptData(newPptData);
        return;
      }
      //只有level1、level2
      newPptData.nodes[level1Index].nodes = newPptData.nodes[level1Index].nodes || [];
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        const nodes = [...newPptData.nodes[level1Index].nodes];
        nodes.splice(level2Index + 1, 0, {
          nodeName: "",
          nodes: [],
        });
        newPptData.nodes[level1Index].nodes = nodes;
        nodeNo = 1;
        transformData(newPptData);
        setPptData(newPptData);
        return;
      }
      // 只有level1、level2、level3
      newPptData.nodes[level1Index].nodes[level2Index].nodes =
        newPptData.nodes[level1Index].nodes[level2Index].nodes || [];
      const nodes = [...newPptData.nodes[level1Index].nodes[level2Index].nodes];
      nodes.splice(level3Index + 1, 0, {
        nodeName: "",
        nodeText: [""],
      });
      newPptData.nodes[level1Index].nodes[level2Index].nodes = nodes;
      nodeNo = 1;
      transformData(newPptData);
      setPptData(newPptData);
    },
    [pptData, setPptData]
  );

  const deleteNode = useCallback(
    ({ level1Index, level2Index, level3Index }) => {
      const newPptData = cloneDeep(pptData);
      newPptData.nodes = newPptData.nodes || [];
      // 只有level1
      if (!level2Index && level2Index !== 0) {
        newPptData.nodes = newPptData.nodes.filter((ignore, index) => index !== level1Index);
        nodeNo = 1;
        transformData(newPptData);
        setPptData(newPptData);
        return;
      }
      // 只有level1、level2
      newPptData.nodes[level1Index].nodes = newPptData.nodes[level1Index].nodes || [];
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        newPptData.nodes[level1Index].nodes = newPptData.nodes[level1Index].nodes.filter(
          (ignore, index) => index !== level2Index
        );
        nodeNo = 1;
        transformData(newPptData);
        setPptData(newPptData);
        return;
      }
      // 只有level1、level2、level3
      newPptData.nodes[level1Index].nodes[level2Index].nodes =
        newPptData.nodes[level1Index].nodes[level2Index].nodes || [];
      newPptData.nodes[level1Index].nodes[level2Index].nodes = newPptData.nodes[level1Index].nodes[
        level2Index
      ].nodes.filter((ignore, index) => index !== level3Index);
      nodeNo = 1;
      transformData(newPptData);
      setPptData(newPptData);
    },
    [pptData, setPptData]
  );

  const addContent = useCallback(
    ({ level1Index, level2Index, level3Index }) => {
      const newPptData = cloneDeep(pptData);
      newPptData.nodes = newPptData.nodes || [];
      // level1
      if (!level2Index && level2Index !== 0) {
        newPptData.nodes[level1Index].nodeText = newPptData.nodes[level1Index].nodeText || [];
        const nodes = [...newPptData.nodes[level1Index].nodeText];
        nodes.splice(nodes.length + 1, 0, "");
        newPptData.nodes[level1Index].nodeText = nodes;
        setPptData(newPptData);
      }
      // level1 level2
      if ((!!level2Index || level2Index === 0) && !level3Index && level3Index !== 0) {
        newPptData.nodes[level1Index].nodes[level2Index].nodeText =
          newPptData.nodes[level1Index].nodes[level2Index].nodeText || [];
        const nodes = [...newPptData.nodes[level1Index].nodes[level2Index].nodeText];
        nodes.splice(nodes.length + 1, 0, "");
        newPptData.nodes[level1Index].nodes[level2Index].nodeText = nodes;
        setPptData(newPptData);
      }
      // level1 level2 level3
      if (level3Index !== undefined) {
        newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index].nodeText =
          newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index].nodeText || [];
        const nodes = [...newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index].nodeText];
        nodes.splice(nodes.length + 1, 0, "");
        newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index].nodeText = nodes;
        setPptData(newPptData);
      }
    },
    [pptData, setPptData]
  );

  const deleteContent = useCallback(
    ({ nodeTextIndex, level1Index, level2Index, level3Index }) => {
      const newPptData = cloneDeep(pptData);
      newPptData.nodes = newPptData.nodes || [];
      newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index].nodeText =
        newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index].nodeText || [];
      newPptData.nodes[level1Index].nodes[level2Index].nodes[level3Index].nodeText = newPptData.nodes[
        level1Index
      ].nodes[level2Index].nodes[level3Index].nodeText.filter((ignore, index) => index !== nodeTextIndex);
      setPptData(newPptData);
    },
    [pptData, setPptData]
  );

  const { run: getPPTToken, loading } = useRequest(
    (params) =>
      dataService.getPPTToken({
        id: messageId,
      }),
    {
      manual: true,
      onSuccess: (res) => {
        setPptToken(res.data.token);
        status === 5 && setShowModal(true);
        status === 2 && loadPptx(text, res.data.token);
      },
      onError: (err) => feedback(err.data || "操作失败"),
    }
  );

  const onOk = useCallback(() => {
    setMdData(formatMd(pptData));
    getPPTToken();
  }, [pptData, setPptData, pptToken]);

  const loadPptx = useCallback((id, token) => {
    const url = `https://docmee.cn/api/ppt/loadPptx?id=${id}`;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.setRequestHeader("token", token);
    xhr.send();
    xhr.onload = function () {
      if (this.status === 200) {
        const resp = JSON.parse(this.responseText);
        if (resp.code !== 0) {
          alert(resp.message);
          return;
        }
        let obj = {};
        obj[messageId] = resp.data.pptInfo;
        setHistoryPptInfo({
          ...historyPptInfo,
          ...obj,
        });
      }
    };
    xhr.onerror = function (e) {
      console.error(e);
    };
  }, []);

  const downloadPptx = useCallback(
    (id) => {
      const url = "https://docmee.cn/api/ppt/downloadPptx";
      const xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.setRequestHeader("token", pptToken);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify({ id }));
      xhr.onload = function () {
        if (this.status === 200) {
          const resp = JSON.parse(this.responseText);
          const fileUrl = resp.data.fileUrl;
          const a = document.createElement("a");
          a.href = fileUrl;
          a.download = `${resp.data.subject || "download"}.pptx`;
          a.click();
        }
      };
      xhr.onerror = function (e) {
        console.error(e);
      };
    },
    [pptToken]
  );

  return (
    <div className="ppt-bubble">
      {status === 5 && (
        <>
          <div className="ppt-outline-title">{`以下是以“${text.title}”为主题的PPT大纲内容`}</div>
          <p className="ppt-outline-subtitle">您可以编辑下方PPT大纲：</p>
          <div className="ppt-outline-editor">
            <EditorContent
              error={error}
              pptData={pptData}
              updateText={updateText}
              addNode={addNode}
              deleteNode={deleteNode}
              addContent={addContent}
              deleteContent={deleteContent}
            />
          </div>
          {!checking && finished && !isContent && (
            <BubbleToolbar
              text={text}
              createdAt={createdAt}
              setComposerText={setComposerText}
              messageId={messageId}
              position={position}
              isLast={isLast}
              isContent={isContent}
              isBasic={true}
              status={status}
            />
          )}
          <div className="ppt-buttons">
            <Button
              type="primary"
              onClick={onOk}
              icon={<ActionIcons type="edit" />}
              className="ppt-button"
              style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat", // 背景图片不重复
              }}
            >
              生成PPT
            </Button>
          </div>
        </>
      )}
      {status === 2 && (
        <>
          {historyPptInfo && historyPptInfo[messageId] ? (
            <div className="history-ppt">
              <div>{`已生成"${historyPptInfo[messageId].subject}"为主题的PPT，点击下方图片预览查看`}</div>
              <div
                className="history-ppt-img"
                ref={imgRef}
                onClick={() => {
                  getPPTToken();
                  setShowModal(true);
                }}
              >
                <>
                  <img
                    src={`${historyPptInfo[messageId].coverUrl}?token=${pptToken}`}
                    style={{ filter: hoverImg ? "blur(2px)" : "none" }}
                  />
                  <div className="image-eye">
                    {hoverImg && <EyeOutlined style={{ fontSize: 30, color: "rgb(96, 165, 250)" }} />}
                  </div>
                </>
              </div>
              {!checking && finished && !isContent && (
                <BubbleToolbar
                  text={text}
                  createdAt={createdAt}
                  setComposerText={setComposerText}
                  messageId={messageId}
                  position={position}
                  isLast={isLast}
                  isContent={isContent}
                  isBasic={true}
                  status={status}
                />
              )}
              <div className="ppt-buttons">
                <Button
                  type="primary"
                  onClick={() => {
                    downloadPptx(historyPptInfo[messageId].id);
                  }}
                  icon={<ActionIcons type="download" />}
                  className="ppt-button"
                  style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat", // 背景图片不重复
                  }}
                >
                  下载PPT
                </Button>
              </div>
            </div>
          ) : (
            <div className="history-ppt-loading">
              <Spin tip="加载中..." />
            </div>
          )}
        </>
      )}
      <PPTModal
        show={showModal}
        historyPptInfo={historyPptInfo}
        status={status}
        setShowModal={setShowModal}
        pptToken={pptToken}
        mdData={mdData}
        messageId={messageId}
      />
    </div>
  );
});

PPTBubble.displayName = "PPTBubble";
export default PPTBubble;
