import { message, notification } from "antd";
import { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import { store } from "@/store";
import EventSourceFactory from "@/eventsource/EventSourceFactory";
import is from "is_js";
import { changeTheme } from "@/utils/index";
import useScreenSize from "@/hooks/useScreenSize";
import ChatLayout from "./components/chat/Layout";
import "./App.less";
import "./assets/font/font.css";

const AgreementLayout = lazy(() => import("./components/agreement/Layout"));
const CanvasLayout = lazy(() => import("./components/canvas/Layout"));
const DocumentLayout = lazy(() => import("./components/document/Layout"));
const SaleLayout = lazy(() => import("./components/sale/Layout"));
const UserBenefit = lazy(() => import("./components/user/UserBenefit"));
const HomePage = lazy(() => import("./components/home/Layout"));
const WechatPage = lazy(() => import("./components/wechat/redirect"));

message.config({
  maxCount: 1,
  top: 80,
});

notification.config({
  maxCount: 2,
});

function App() {
  const dispatch = useDispatch();
  const { visitorId } = useSelector((state) => state.token);
  const { jwt } = useSelector((state) => state.token);
  const [param, setParam] = useSearchParams();
  const from = param.get("from");
  const { dark } = useSelector((state) => state.theme);
  const { middle } = useScreenSize();

  // 修改mobile模式下，leftmenu去除，主题样式
  useEffect(() => {
    !middle && changeTheme(dark);
  }, [middle]);

  useEffect(() => {
    if (visitorId) {
      if (!window.__event_source__) {
        window.__event_source__ = new EventSourceFactory(store);
      }
      return;
    }
    dispatch.token.init({});
  }, [visitorId, dispatch]);

  useEffect(() => {
    dispatch.browser.update({
      isChrome: is.chrome(),
      isFirefox: is.firefox(),
      isMac: is.mac(),
    });
  }, []);

  useEffect(() => {
    const documentHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", documentHeight);
    documentHeight();
  }, []);

  return (
    <Routes>
      {jwt ? (
        <Route
          index
          element={
            <Navigate
              to={from ? `/chat?from=${from}` : "/chat"}
              replace={true}
            />
          }
        />
      ) : (
        <Route
          index
          element={
            <Navigate
              to={from ? `/home?from=${from}` : "/home"}
              replace={true}
            />
          }
        />
      )}
      <Route
        path="/chat"
        element={<ChatLayout />}
      />
      <Route
        path="/document"
        element={
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Loading...
              </div>
            }
          >
            <DocumentLayout />
          </Suspense>
        }
      />
      <Route
        path="/agreement"
        element={
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Loading...
              </div>
            }
          >
            <AgreementLayout />
          </Suspense>
        }
      />
      <Route
        path="/sale"
        element={
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Loading...
              </div>
            }
          >
            <SaleLayout />
          </Suspense>
        }
      />
      <Route
        path="/userbenefit"
        element={
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Loading...
              </div>
            }
          >
            <UserBenefit />
          </Suspense>
        }
      />
      <Route
        path="/home"
        element={
          <Suspense>
            <HomePage />
          </Suspense>
        }
      />
      <Route
        path="/wechat"
        element={
          <Suspense>
            <WechatPage />
          </Suspense>
        }
      />
      <Route
        path="/canvas"
        element={
          <Suspense
            fallback={
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Loading...
              </div>
            }
          >
            <CanvasLayout />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Navigate
            to={"chat"}
            replace={true}
          />
        }
      />
    </Routes>
  );
}

export default App;
