import { useCallback, useRef } from "react";
import { Button, Tooltip, Popconfirm } from "antd";
import { DeleteOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import EditorInput from "../EditorInput";
import { EditorIcons } from "./EditorIcons";
import { useHover } from "ahooks";
import cls from "classnames";
import styles from "./index.module.less";

const EditorParagraph = ({
  children,
  error = [],
  level1,
  level2,
  level3,
  childSize,
  nodeName,
  nodeText,
  nodeNo,
  deleteDisabled,
  updateText,
  addNode,
  addChildNode,
  deleteNode,
  showText,
  addContent,
  deleteContent,
}) => {
  const isLevel2 = !!level2 || level2 === 0;
  const isLevel3 = !!level3 || level3 === 0;
  const tagType = isLevel3 ? "h6" : isLevel2 ? "h5" : "h4";
  const typeName = [isLevel3 ? "三级" : isLevel2 ? "二级" : "一级", "标题"].join("");
  const nextTypeName = [isLevel2 ? "三级" : "二级", "标题"].join("");
  const typeNo = `${level1 + 1}${level2 + 1 ? `.${level2 + 1}` : ""}${level3 + 1 ? `.${level3 + 1}` : ""}`;
  const addRowRef = useRef(null);
  const isAddRow = useHover(addRowRef);
  const addLeafRef = useRef(null);
  const isAddLeaf = useHover(addLeafRef);
  const deleteRef = useRef(null);
  const isDelete = useHover(deleteRef);
  const addTextRef = useRef(null);
  const deletecontentRef = useRef(null);
  const isDeleteContent = useHover(deletecontentRef);

  return (
    <div className={cls(styles.editorParagraph, "editorParagraph", tagType)}>
      <div className="editorParagraphContent">
        <div className="editorParagraphAction">
          {isLevel3 ? null : isLevel2 ? null : (
            <Tooltip title={`下方插入${typeName}`}>
              <Button
                type="link"
                key={`下方插入${typeName}`}
                size="small"
                onClick={(e) =>
                  isLevel3
                    ? addNode({
                        level1Index: level1,
                        level2Index: level2,
                        level3Index: level3 !== undefined ? level3 : childSize - 1 || 0,
                      })
                    : isLevel2
                      ? addNode({
                          level1Index: level1,
                          level2Index: level2 !== undefined ? level2 : childSize - 1 || 0,
                        })
                      : addNode({ level1Index: level1 })
                }
                ref={addRowRef}
              >
                <span className="anticon anticon-delete">
                  {isAddRow ? (
                    <EditorIcons type={isLevel3 ? "addH3Hover" : isLevel2 ? "addH2Hover" : "addH1Hover"} />
                  ) : (
                    <EditorIcons type={isLevel3 ? "addH3" : isLevel2 ? "addH2" : "addH1"} />
                  )}
                </span>
              </Button>
            </Tooltip>
          )}
          {isLevel3 ? (
            <Tooltip title={"添加内容"}>
              <Button
                type="link"
                key={`新增内容${level1}-${level2}-${level3}`}
                size="small"
                onClick={(e) => {
                  isLevel3
                    ? addContent({
                        level1Index: level1,
                        level2Index: level2,
                        level3Index: level3,
                      })
                    : isLevel2
                      ? addContent({ level1Index: level1, level2Index: level2 })
                      : addContent({ level1Index: level1 });
                }}
                ref={addTextRef}
              >
                <span className="anticon anticon-delete">
                  <PlusOutlined />
                </span>
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={`新增${nextTypeName}`}>
              <Button
                type="link"
                key={`新增${nextTypeName}${level1}-${level2}-${level3}`}
                size="small"
                onClick={(e) =>
                  isLevel2
                    ? addChildNode({ level1Index: level1, level2Index: level2, level3Index: childSize - 1 || 0 })
                    : addChildNode({ level1Index: level1, level2Index: childSize - 1 || 0 })
                }
                ref={addLeafRef}
              >
                <span className="anticon anticon-delete">
                  {isAddLeaf ? (
                    <EditorIcons type={isLevel2 ? "addH3Hover" : "addH2Hover"} />
                  ) : (
                    <EditorIcons type={isLevel2 ? "addH3" : "addH2"} />
                  )}
                </span>
              </Button>
            </Tooltip>
          )}
          <Popconfirm
            title={`确认删除【${typeName}${typeNo}】？`}
            onConfirm={() => deleteNode({ level1Index: level1, level2Index: level2, level3Index: level3 })}
            cancelText="取消"
            okText="删除"
          >
            <Tooltip title={deleteDisabled ? `至少需要一个章节，不能删除` : `删除${typeName}`}>
              <Button
                type="link"
                key={`删除${typeName}`}
                size="small"
                disabled={deleteDisabled}
                ref={deleteRef}
              >
                <span className="anticon anticon-delete">
                  {isDelete ? <EditorIcons type="deleteIconHover" /> : <EditorIcons type="deleteIcon" />}
                </span>
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
        {!isLevel3 && (
          <div className="ppt-pages">
            <EditorIcons type="pptIcon" />
            <div className="ppt-pages-title">幻灯片{nodeNo}</div>
          </div>
        )}
        <EditorInput
          invalid={error.includes(
            isLevel3
              ? ["name", level1, level2, level3].join("-")
              : isLevel2
                ? ["name", level1, level2].join("-")
                : ["name", level1].join("-")
          )}
          tagType={tagType}
          required={true}
          label={`${typeNo}`}
          placeholder="请输入标题"
          value={nodeName}
          onChange={({ text }) =>
            updateText({ text, type: "nodeName", level1Index: level1, level2Index: level2, level3Index: level3 })
          }
        />
        {showText &&
          Array.isArray(nodeText) &&
          nodeText.map((item, index) => {
            return (
              <div
                className="editor-paragraph-content"
                key={item}
              >
                <EditorInput
                  key={item}
                  tagType="p"
                  required={false}
                  placeholder="请输入内容"
                  value={item}
                  label="point"
                  onChange={({ text }) =>
                    updateText({
                      text,
                      nodeTextIndex: index,
                      type: "nodeText",
                      level1Index: level1,
                      level2Index: level2,
                      level3Index: level3,
                    })
                  }
                />
                <Tooltip title={"删除"}>
                  <Button
                    type="link"
                    size="small"
                    className="editor-paragraph-content-delete"
                    ref={deletecontentRef}
                    onClick={(e) => {
                      deleteContent({
                        nodeTextIndex: index,
                        level1Index: level1,
                        level2Index: level2,
                        level3Index: level3,
                      });
                    }}
                  >
                    <span className="anticon anticon-delete">
                      {isDeleteContent ? <EditorIcons type="deleteIconHover" /> : <EditorIcons type="deleteIcon" />}
                    </span>
                  </Button>
                </Tooltip>
              </div>
            );
          })}
      </div>
      {children}
    </div>
  );
};

export default EditorParagraph;
