import { ControlOutlined } from "@ant-design/icons";
import { cloneDeep } from "lodash-es";

export const PPT_EMPTY = {
  title: "",
  id: Date.now(),
  nodes: [
    {
      nodeName: "",
      nodes: [
        {
          nodeName: "",
          nodes: [
            {
              nodeName: "",
              nodeText: [""],
            },
          ],
        },
      ],
    },
  ],
};

export const parsePPTData = (data) => {
  const pptData = cloneDeep(PPT_EMPTY);
  pptData.title = data.title || "";
  pptData.id = Date.now();

  const nodes = (data.chapters || []).map((node) => {
    const childNodes = (node.pages || []).map((childNode) => {
      const grandChildNodes = (childNode.contents || []).map((grandChildNode) => {
        return {
          nodeName: `${grandChildNode.title || ""}`,
          nodeText: grandChildNode.texts || [],
        };
      });
      return {
        nodeName: `${childNode.title || ""}`,
        nodes: grandChildNodes || [],
      };
    });
    return {
      nodeName: `${node.title || ""}`,
      nodes: childNodes || [],
    };
  });
  if (nodes.length) {
    pptData.nodes = nodes;
  }
  return pptData;
};

export const formatMd = (pptData) => {
  const chapters = pptData.nodes.map((item, index) => {
    const pages = (item.nodes || []).map((childNode, childIndex) => {
      const contents = (childNode.nodes || []).map((grandChildNode, grandChildIndex) => {
        return [
          `#### ${grandChildNode.nodeName}`,
          grandChildNode?.nodeText && grandChildNode.nodeText.length > 0
            ? grandChildNode.nodeText
                .map((val) => {
                  if (val) {
                    return `- ${val}`;
                  }
                })
                .join("\n")
            : "",
        ].join("\n");
      });
      return [`### ${childNode.nodeName}`, contents.join("\n")].join("\n");
    });
    return [`## ${item.nodeName}`, pages.join("\n")].join("\n");
  });
  return `# ${pptData.title}\n${chapters.join("\n")}`;
};
