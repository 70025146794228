import { Fragment, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Image, Modal, Tooltip } from "antd";
import CustomerServiceDark from "@/assets/CustomerService-dark.png";
import CustomerServiceLight from "@/assets/CustomerService-light.png";
import styles from "./index.module.less";
import FloatSiderIcons from "@/icons/FloatSiderIcons";

function ServiceButton() {
  const [serviceOpen, setServiceOpen] = useState(false);
  const { dark } = useSelector((state) => state.theme);

  const onClick = (window.docGptOpenServiceModal = useCallback(() => {
    setServiceOpen(true);
  }, [setServiceOpen]));

  return (
    <Fragment>
      <Tooltip
        title="客服"
        placement="right"
      >
        <div
          className="leftMenuBottom-Btn service-button"
          onClick={onClick}
        >
          <FloatSiderIcons type="userservice" />
        </div>
      </Tooltip>
      <Modal
        title="客服微信"
        open={serviceOpen}
        onCancel={() => setServiceOpen(false)}
        footer={null}
        wrapClassName={styles.serviceModal}
        width={300}
        bodyStyle={{ height: "250" }}
      >
        <Image
          src={dark ? CustomerServiceDark : CustomerServiceLight}
          width={250}
          preview={false}
        />
      </Modal>
    </Fragment>
  );
}

export default ServiceButton;
