import { getUploadConfig } from "@/components/common/GptComposer/UploadButton/uploadConfig";
import { message } from "antd";
import { data as dataService } from "@/services/data";

export const uploadFiles = async (files, dropExtensions, maxCount) => {
  message.config({
    top: 100,
    duration: 2,
    maxCount: 9,
  });
  const config = getUploadConfig(null);
  const finished = Array(files.length).fill(false);
  for (let index = 0; index < files.length; index++) {
    if (finished.filter((x) => !!x).length === finished.length) {
      break;
    }
    const file = files[index];
    const isImage = file.type.indexOf("image/") > -1;
    const size = isImage ? 3 : config.limitSize;
    const type = isImage ? "图片" : "文件";
    const extension = file.name.split(".").pop().toLowerCase();
    if (file.size > size * 1024 * 1024) {
      file.uploadError = true;
      message.error(`【${file.name}${type}】大小不能超过 ${size} MB，已取消上传`);
      finished[index] = true;
      continue;
    }
    if (dropExtensions.length > 0 && !dropExtensions.includes(extension)) {
      file.uploadError = true;
      message.warning(`【${file.name}${type}】上传类型不符合要求，已取消上传, 上传类型只能是${dropExtensions}`);
      finished[index] = true;
      continue;
    }
    if (index >= maxCount) {
      file.uploadError = true;
      message.error(`最多上传${maxCount}个文件`);
      finished[index] = true;
      continue;
    }
    const formData = new FormData();
    formData.append("file", file);
    const response = await dataService.uploadMedia(formData);
    finished[index] = {
      name: file.name,
      response,
      isImage,
      status: "done",
      type: file.type,
      size: file.size,
      percent: 100,
      loading: false,
      originFileObj: file,
      lastModifiedDate: new Date(),
      error: undefined,
    };
  }
  return finished.filter((x) => !!x && x.name);
};
