import styles from "./index.module.less";
import ShareButton from "./ShareButton";
import ServiceButton from "./ServiceButton";
import VipButton from "./VipButton";
import MessageButton from "./MessageButton";
import SpecialButton from "./SpecialButton";
import NewUserButton from "./NewUserButton";
import ModeSwitch from "../../WindowHeader/WindowToolbar/ModeSwitch";
import { useSelector } from "react-redux";

function LeftMenuBottom() {
  return (
    <div className={styles.LeftMenuBottom}>
      {/* <SpecialButton /> */}
      <NewUserButton />
      <VipButton />
      <MessageButton />
      <ShareButton />
      <ServiceButton />
      <ModeSwitch />
    </div>
  );
}

export default LeftMenuBottom;
